import calculatePercentiles from "../utilities/calculatePercentiles";
import calculateFrequencyInBands from "../utilities/calculateFrequencyInBands";

export function performCalculations(data) {
  // console.log("data in performCalcs component:",data);

  var years = 0; // initialize

  // console.log ("target_wd drop down requested",parseInt(data.target_wd));

  const current_age = parseInt(data.current_age);
  const retirement_age = parseInt(data.retirement_age);
  const max_projection_age = parseInt(data.max_projection_age);
  const scenario_count = 1000; // parseInt(data.scenario_count);
  const initial_assets_pre_tax = parseInt(data.initial_assets_pre_tax);
  const initial_assets_after_tax = 0; // parseInt(data.initial_assets_after_tax);
  const pre_tax_contrib = parseInt(data.pre_tax_contrib);
  const after_tax_contrib = 0; //parseInt(data.after_tax_contrib);
  const ss_income = parseInt(data.ss_income);
  const ss_start_age = parseInt(data.ss_start_age);
  const ss_cola_rate = 0.01 * parseInt(data.ss_cola_rate);
  const tax_rate_wd_pre_tax_asset = parseInt(data.tax_rate_wd_pre_tax_asset); // parseInt(data.tax_rate_wd_pre_tax_asset);
  const tax_rate_wd_after_tax_asset = parseInt(data.tax_rate_wd_pre_tax_asset); // parseInt(data.tax_rate_wd_after_tax_asset);
  const target_wd = parseInt(data.target_wd);
  const random_or_fixed_int_assump = "R"; // data.random_or_fixed_int_assump.slice(0,1); // Comes in as random or fixed so take first letter
  const fixed_int_assump = 0; // parseInt(data.fixed_int_assump);
  const random_int_min = parseInt(data.random_int_min);
  const random_int_max = parseInt(data.random_int_max);
  const random_or_fixed_infl_rate = "R"; // data.random_or_fixed_infl_rate.slice(0,1);// // Comes in as random or fixed so take first letter
  const fixed_infl_assump = 0; // parseInt(data.fixed_infl_assump);
  const random_infl_min = parseInt(data.random_infl_min);
  const random_infl_max = parseInt(data.random_infl_max);
  const family1_inheritance = 0;
  const family2_inheritance = 0;
  const inheritance_age_family1 = 0;
  const inheritance_age_family2 = 0;

  // temp till set up code to apply and track pre and after tax balances
  const tax_rate =
    (0.01 * (tax_rate_wd_pre_tax_asset + tax_rate_wd_after_tax_asset)) / 2;

  // console.log ("tax_rate",tax_rate);
  // console.log ("data.tax_rate_wd_pre_tax_asset",data.tax_rate_wd_pre_tax_asset);

  // console.log ("max_projection_age",max_projection_age);

  // Set column numbers

  const scen_col = 0;
  const age_col = 1;
  const boy_bal_pre_tax_col = 2;
  const boy_bal_after_tax_col = 3;
  const boy_bal_total_col = 4;
  const deposit_col = 5;
  const ssn_col = 6;
  const inheritance_col = 7;
  const int_rate_col = 8; //// interest rate column; 2 decimal places
  const int_col = 9;
  const infl_col = 10; // inflation rate column; 2 decimal places
  // const income_pre_tax_col = 11;
  // const income_after_tax_col = 12;
  const income_total_col = 13;
  // const tax_pre_tax_assets_col = 14;
  // const tax_after_tax_assets_col = 15;
  const tax_total_col = 16;
  const total_wd_col = 17;
  const eoybal_pre_tax_col = 18;
  const eoybal_after_tax_col = 19;
  const eoybal_total_col = 20;
  const net_wd_col = 21;
  const int_rate_product_col = 22;
  const infl_rate_product_col = 23;
  const CAGR_int_rate_col = 24;
  const CAGR_infl_rate_col = 25;
  const year_col = 26;
  const id_col = 27;

  // build array of net wds to loop through

  var net_wd_array = [];

  // console.log(net_wd_array);
  // not looping now; so just single target_wd in net_wd_array
  for (var wd = target_wd; wd <= target_wd; wd += 1) {
    net_wd_array.push(wd);
  }

  // console.log(net_wd_array);

  //build projection array
  const rows = scenario_count * (1 + max_projection_age - current_age);

  // console.log("scenario_count:", scenario_count);
  // console.log("max_projection_age:", max_projection_age);
  // console.log("current_age:", current_age);

  const cols = id_col; // since count starts at zero max # of cols needed is x-1

  // console.log("Rows:", rows);
  // console.log("Cols:", cols);

  var projection = Array(rows)
    .fill()
    .map(() => Array(cols).fill(0)); // fill with zeroes

  // The maximum is inclusive and the minimum is inclusive
  function getRandom(min, max) {
    return Math.random() * (max - min) + min;
  }

  let uniqueId = 1;

  for (let z = 1; z <= 1; z++) {
    let t = 1;
    while (t <= scenario_count) {
      let i = 0;

      while (i <= max_projection_age - current_age) {
        let row_index = i + (t - 1) * (1 + max_projection_age - current_age); // + (z - 1) *(scenario_count)*(1 + max_projection_age - current_age);
        projection[row_index][scen_col] = t;
        projection[row_index][age_col] = current_age + i; // age
        projection[row_index][year_col] =
          1 + projection[row_index][age_col] - current_age; // year

        if (i === 0)
          projection[row_index][boy_bal_pre_tax_col] = initial_assets_pre_tax;
        if (i > 0)
          projection[row_index][boy_bal_pre_tax_col] =
            projection[i - 1][eoybal_pre_tax_col];

        if (i === 0)
          projection[row_index][boy_bal_after_tax_col] =
            initial_assets_after_tax;
        if (i > 0)
          projection[row_index][boy_bal_after_tax_col] =
            projection[i - 1][eoybal_after_tax_col];

        var current_assets_pre_tax = projection[row_index][boy_bal_pre_tax_col];
        var current_assets_after_tax =
          projection[row_index][boy_bal_after_tax_col];

        if (i === 0)
          projection[row_index][boy_bal_total_col] =
            current_assets_pre_tax + current_assets_after_tax;
        if (i > 0)
          projection[row_index][boy_bal_total_col] =
            projection[i - 1][eoybal_total_col];

        if (i <= retirement_age - current_age)
          projection[row_index][deposit_col] =
            pre_tax_contrib + after_tax_contrib;

        if (projection[row_index][age_col] >= ss_start_age)
          years = current_age + i - ss_start_age;
        else years = 0;

        if (projection[row_index][age_col] >= ss_start_age)
          var ssn_dep = ss_income;
        else ssn_dep = 0;

        projection[row_index][ssn_col] =
          ssn_dep * Math.pow(1 + ss_cola_rate, years); // ** = same as "power" function

        if (projection[row_index][age_col] === inheritance_age_family1)
          projection[row_index][inheritance_col] = family1_inheritance;

        if (projection[row_index][age_col] === inheritance_age_family2)
          projection[row_index][inheritance_col] = family2_inheritance;

        if (random_or_fixed_int_assump === "F")
          projection[row_index][int_rate_col] = 0.01 * fixed_int_assump;
        if (random_or_fixed_int_assump === "R")
          projection[row_index][int_rate_col] =
            (0.01 *
              Math.round(
                getRandom(random_int_min * 100, random_int_max * 100)
              )) /
            100;

        // console.log("int rate", projection[row_index][int_rate_col]);

        if (random_or_fixed_infl_rate === "F")
          projection[row_index][infl_col] = fixed_infl_assump;
        if (random_or_fixed_infl_rate === "R")
          projection[row_index][infl_col] =
            (0.01 *
              Math.round(
                getRandom(random_infl_min * 100, random_infl_max * 100)
              )) /
            100;

        if (i === 0) {
          projection[row_index][int_rate_product_col] =
            projection[row_index][int_rate_col] + 1;
          projection[row_index][infl_rate_product_col] =
            projection[row_index][infl_col] + 1;

          projection[row_index][CAGR_int_rate_col] =
            projection[row_index][int_rate_col];
          projection[row_index][CAGR_infl_rate_col] =
            projection[row_index][infl_col];

          // console.log("year", projection[row_index][year_col]);
          // console.log("rowindex", row_index);
          // console.log("int_rate", projection[row_index][int_rate_col]);

          // console.log(
          //   "projection[row_index][int_rate_product_col]",
          //   projection[row_index][int_rate_product_col]
          // );
          // console.log(
          //   "(projection[row_index][int_rate_col] + 1)",
          //   projection[row_index][int_rate_col] + 1
          // );

          // console.log(
          //   "projection[row_index][CAGR_int_rate_col])",
          //   projection[row_index][CAGR_int_rate_col]
          // );
        } else if (i > 0) {
          projection[row_index][int_rate_product_col] =
            projection[row_index - 1][int_rate_product_col] *
            (projection[row_index][int_rate_col] + 1);
          // console.log("---\n");
          // console.log("year", projection[row_index][year_col]);
          // console.log("rowindex", row_index);
          // console.log("int_rate", projection[row_index][int_rate_col]);

          // console.log(
          //   "projection[row_index][int_rate_product_col]",
          //   projection[row_index][int_rate_product_col]
          // );
          // console.log(
          //   "(projection[row_index][int_rate_col] + 1)",
          //   projection[row_index][int_rate_col] + 1
          // );

          projection[row_index][infl_rate_product_col] =
            projection[row_index - 1][infl_rate_product_col] *
            (projection[row_index][infl_col] + 1);

          // console.log("i:", i);
          // console.log(
          //   "projection[row_index][int_rate_product_col]",
          //   projection[row_index][int_rate_product_col]
          // );
          projection[row_index][CAGR_int_rate_col] =
            Math.pow(projection[row_index][int_rate_product_col], 1 / (i + 1)) -
            1;

          // console.log(
          //   "projection[row_index][CAGR_int_rate_col])",
          //   projection[row_index][CAGR_int_rate_col]
          // );

          projection[row_index][CAGR_infl_rate_col] =
            Math.pow(
              projection[row_index][infl_rate_product_col],
              1 / (i + 1)
            ) - 1;
        }

        var infl_rate = projection[row_index][infl_col];

        if (row_index > 0)
          projection[row_index][income_total_col] = Math.round(
            projection[row_index - 1][income_total_col] * (1 + infl_rate),
            0
          );
        // need to put line below 2nd in order to reset net ann wd once start
        // a new scenario
        if (projection[row_index][age_col] === current_age)
          projection[row_index][income_total_col] = net_wd_array[z - 1]; // net_annual_withdrawal;

        projection[row_index][int_col] = Math.round(
          projection[row_index][int_rate_col] *
            (projection[row_index][boy_bal_total_col] +
              projection[row_index][deposit_col] +
              projection[row_index][inheritance_col] +
              projection[row_index][ssn_col] -
              projection[row_index][total_wd_col]),
          0
        );

        projection[row_index][tax_total_col] = Math.round(
          tax_rate *
            (projection[row_index][income_total_col] +
              projection[row_index][ssn_col] +
              projection[row_index][inheritance_col]),
          0
        );

        projection[row_index][total_wd_col] =
          projection[row_index][income_total_col] +
          projection[row_index][tax_total_col];

        projection[row_index][eoybal_total_col] = Math.round(
          Math.max(
            projection[row_index][boy_bal_total_col] +
              projection[row_index][deposit_col] +
              projection[row_index][ssn_col] +
              projection[row_index][int_col] -
              projection[row_index][total_wd_col],
            0
          ),
          0
        );

        projection[row_index][net_wd_col] = net_wd_array[z - 1];
        projection[row_index][id_col] = uniqueId;
        // Increment the unique ID
        uniqueId++;

        // Check if the value has hit zero for the first time
        if (projection[row_index][eoybal_total_col] === 0) {
          // Record that first zero or perform other tasks if needed

          // Break out of the loop and go to next scenario
          break;
        }

        // console.log("row_index",row_index);
        // console.log("i",i);
        i++;
      }
      // console.log("t",t);
      t++;
    }
  }

  //remove rows with mult cols of zeros
  projection = projection.filter((row) => {
    return row.some((value) => value !== 0 && !isNaN(value));
  });

  // console.log ("projection in cf proj component:", projection)

  const percentileData = calculatePercentiles(projection);
  const histogramData = calculateFrequencyInBands(projection);

  // const stringifiedData = JSON.stringify(projection, null, 4);
  // console.log(
  //   "projection array at end of perform calculations function",
  //   stringifiedData.slice(0, 1000)
  // );

  // const stringifiedData2 = JSON.stringify(percentileData, null, 4);
  // console.log("percentile array at end of perform calculations function", stringifiedData2);

  // const stringifiedData3 = JSON.stringify(histogramData, null, 4);
  // console.log(
  //   "histogram array at end of perform calculations function",
  //   stringifiedData3
  // );

  // reminder if just returning one array do not put in {}!!

  // function projectionToNamedFieldArray(projection) {
  //   return projection.map((row) => ({
  //     scenario: row[0],
  //     att_age: row[1],
  //     assets_boy: row[4],
  //     ss_income: Math.round(row[6]),
  //     interest_rate: Math.round(100 * row[8], 2),
  //     interest: row[9],
  //     inflation_rate: Math.round(100 * row[10], 2),
  //     income_net: row[13], // Adjust this to match your desired structure
  //     cola: row[13] - row[21], // Adjust this to match your desired structure
  //     income_cola: row[13], // Adjust this to match your desired structure
  //     tax: row[16],
  //     wd_total: row[17],
  //     assets_eoy: row[20],
  //   }));
  // }

  // const namedFieldProjection = projectionToNamedFieldArray(projection);

  // console.log ("namedFieldProjection",namedFieldProjection);
  // const projectionJSON = JSON.stringify(namedFieldProjection);

  // console.log ("namedFieldProjection",namedFieldProjection);
  // const stringifiedData = JSON.stringify(namedFieldProjection, null, 4);
  // console.log("projectionJSON in perf calcs fn:", projectionJSON.slice(0,100));

  // console.log("has run perform calculations function");
  return { percentileData, histogramData, projection };
}
