import styled from "styled-components";

export const HamburgerIcon = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;

  div {
    width: 25px;
    height: 3px;
    background-color: black; // ${(props) => props.theme.colors[500]};
    margin: 2px 0;
    transition: transform 0.3s ease-in-out;
  }

  /* Styles for the hamburger animation */
  &.open {
    div:first-child {
      transform: translateY(6px) rotate(45deg);
    }
    div:nth-child(2) {
      opacity: 0;
    }
    div:last-child {
      transform: translateY(-6px) rotate(-45deg);
    }
  }
`;

export const MenuContainer = styled.div`
  // margin-top: 15px;
  margin-bottom: 5px;
  position: relative;
  order: 1; /* Order the menu below the logo and website name */
  // background-color: ${(props) => props.theme.colors[100]};
  background-color: transparent;
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  z-index: 1000;
`;

export const MenuItem = styled.div`
  cursor: pointer;
  padding: 2px 0;
  // transition: background-color 0.3s ease-in-out;
  font-weight: bold;
  font-size: 1rem;
  &:hover {
    // text-decoration: underline;
    color: ${(props) => props.theme.colors[500]};
  }

  @media (max-width: 390px) {
    font-size: 0.9rem;
  }
`;
