import React, { useState, useEffect, Suspense } from "react";

import Footer from "./components/Footer";
import {
  BodyWrapper,
  HeaderContainer,
  LogoTitle,
  HeroContainer,
} from "./styles/AppStyles";
import GlobalStyles from "./styles/GlobalStyles";
import { ThemeProvider } from "styled-components";
import theme from "./styles/Theme";

import { performCalculations } from "./components/CashflowProjection";
import defaultSubmittedData from "./services/DefaultSubmittedData";

import { BsGraphUpArrow } from "react-icons/bs";

import LandingPage from "./components/LandingPage";
import HamburgerMenu from "./components/HamburgerMenu";

// try lazy loading for faster initial load
const Histogram = React.lazy(() => import("./components/Histogram"));
const LongevityMain = React.lazy(() => import("./components/LongevityMain"));
const ProjectionDetails = React.lazy(() =>
  import("./components/ProjectionDetails")
);
const CumulativeRates = React.lazy(() =>
  import("./components/CumulativeRates")
);
const StockGraph = React.lazy(() => import("./components/StockGraph"));
const Results = React.lazy(() => import("./components/Results"));
const Welcome = React.lazy(() => import("./components/Welcome"));
const InputFormGrid = React.lazy(() => import("./components/InputFormGrid"));

// import StockGraph from "./components/StockGraph";
// import Histogram from "./components/Histogram";
// import CumulativeRates from "./components/CumulativeRates";
// import LongevityMain from "./components/LongevityMain";
// import ProjectionDetails from "./components/ProjectionDetails";
// import InputFormGrid from "./components/InputFormGrid";
// import Welcome from "./components/Welcome";
// import Results from "./components/Results";

function App() {
  const [graphData, setGraphData] = useState([]);
  const [histogramDataState, setHistogramDataState] = useState([]);
  const [projectionState, setProjectionState] = useState([]);
  const [formData, setFormData] = useState(defaultSubmittedData);
  const [dataToUse, setDataToUse] = useState({});
  const [finalData3, setFinalData3] = useState([]);

  const updateTargetWd = (newTargetWd) => {
    setFormData((prevData) => ({
      ...prevData,
      target_wd: newTargetWd,
    }));
  };

  // console.log("formData", formData);
  useEffect(() => {
    const isEmpty = (obj) => {
      if (obj === null || obj === undefined) {
        return true; // Treat null and undefined as empty
      }
      return Object.keys(obj).length === 0 && obj.constructor === Object;
    };

    const dataToPass = isEmpty(formData) ? defaultSubmittedData : formData;

    // console.log("isEmpty(formData)", isEmpty(formData));

    // console.log("dataToPass", dataToPass);
    setDataToUse(dataToPass); // Set the data to use in component state

    const { percentileData, histogramData, projection } =
      performCalculations(dataToPass);
    setGraphData(percentileData);
    setHistogramDataState(histogramData);
    setProjectionState(projection);

    // Log dataToUse after it has been updated
    // console.log("dataUse", dataToUse);
    // console.log("formData", formData);
  }, [formData, dataToUse]);

  // console.log ("projectionState in app.js",projectionState.slice(0,100));
  // console.log ("finalData3 in app.js",finalData3);
  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <HeaderContainer className="position-relative">
          <LogoTitle>
            <BsGraphUpArrow size={40} color={"#3777c0"} />
            <h1>Tenacity Analytics</h1>
          </LogoTitle>
          <HamburgerMenu />
        </HeaderContainer>

        <BodyWrapper>
          <HeroContainer>
            <LandingPage />
            <Suspense fallback={null}>
              <Welcome />
            </Suspense>
            <Suspense fallback={null}>
              <InputFormGrid
                onSubmitData={setFormData}
                currentData={formData}
              />
            </Suspense>
            <Suspense fallback={null}>
              <Results
                data={projectionState}
                submitData={dataToUse}
                onUpdateTargetWd={updateTargetWd}
                onPassFinalData3={(data) => setFinalData3(data)} // Set finalData3 directly
              />
            </Suspense>
            <Suspense fallback={null}>
              <StockGraph data={graphData} />
            </Suspense>

            <Suspense fallback={null}>
              {window.innerWidth > 390 && <LongevityMain />}
            </Suspense>
            <Suspense fallback={null}>
              {window.innerWidth > 390 && (
                <ProjectionDetails data={projectionState} data2={finalData3} />
              )}
            </Suspense>

            <Suspense fallback={null}>
              {window.innerWidth > 390 && (
                <CumulativeRates data={projectionState} />
              )}
            </Suspense>

            <Suspense fallback={null}>
              <Histogram data={histogramDataState} />
            </Suspense>
          </HeroContainer>
        </BodyWrapper>
        <Footer />
      </ThemeProvider>
    </>
  );
}

export default App;
