import React, { useState } from "react";
import {
  HamburgerIcon,
  MenuContainer,
  MenuItem,
} from "../styles/HamburgerMenuStyles";

const HamburgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const getOffsetBySection = (sectionId) => {
    // Define different offsets based on section IDs
    const offsetMap = {
      section1: -15, // retirement plan
      section3: -50, // percentiles
      section3a: -50, // portfolio longevity
      section4: -45, // histogram
      section5: -35, // projection cashflows
      // Add more sections and their respective offsets as needed
    };

    // Return the offset for the given section ID
    return offsetMap[sectionId] || 0; // Default to 0 if section ID not found
  };

  const scrollToSection = (sectionId) => () => {
    const section = document.getElementById(sectionId);
    if (section) {
      const offset = getOffsetBySection(sectionId); // Get offset based on section ID
      const targetY =
        section.getBoundingClientRect().top + window.scrollY + offset;
      window.scrollTo(0, targetY); // Instantly jumps to the target section with the offset
      setIsOpen(false); // Close the menu after clicking a menu item
    }
  };

  return (
    <div>
      <HamburgerIcon onClick={toggleMenu}>
        <div></div>
        <div></div>
        <div></div>
      </HamburgerIcon>
      {isOpen && (
        <MenuContainer>
          <MenuItem onClick={scrollToSection("section1")}>Plan</MenuItem>

          <MenuItem onClick={scrollToSection("section3")}>Simulations</MenuItem>
          {window.innerWidth > 390 && (
            <MenuItem onClick={scrollToSection("section3a")}>
              Portfolio Longevity
            </MenuItem>
          )}

          {window.innerWidth > 390 && (
            <MenuItem onClick={scrollToSection("section5")}>Cashflows</MenuItem>
          )}

          {window.innerWidth > 390 && (
            <MenuItem onClick={scrollToSection("section6")}>
              Market Rates
            </MenuItem>
          )}

          <MenuItem onClick={scrollToSection("section4")}>Histogram</MenuItem>
        </MenuContainer>
      )}
    </div>
  );
};

export default HamburgerMenu;
