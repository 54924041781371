const calculatePercentiles = (data) => {
  if (!data || data.length === 0) {
    return null;
  }

  const att_age = [...new Set(data.map((item) => item[1]))]; // Removing duplicates using Set
  const dataMappings = {
    Assets: () => data.map((item) => item[20]),
    "Investment Returns": () => data.map((item) => 100 * item[8]),
    "Inflation Rates": () => data.map((item) => 100 * item[10]),
    "Income (COLA)": () => data.map((item) => item[13]), // Net WD + COLA
    Taxes: () => data.map((item) => item[16]),
    "Total WD": () => data.map((item) => item[17]), // Net WD + COLA + Taxes
  };
  const percentiles = ["5th", "25th", "50th", "75th", "95th"];

  function calculatePercentile(sortedArray, percentile) {
    const index = Math.ceil((percentile / 100) * sortedArray.length) - 1;
    return sortedArray[index];
  }

  const result = [];

  for (let metric in dataMappings) {
    const selectedData = dataMappings[metric]();

    for (let age of att_age) {
      let metricValues = [];

      for (let row = 0; row < data.length; row++) {
        if (data[row][1] === age) {
          metricValues.push(parseFloat(selectedData[row]));
        }
      }
      metricValues.sort((a, b) => a - b);

      for (let percentile of percentiles) {
        let chosenPercentileValue;

        switch (percentile) {
          case "5th":
            chosenPercentileValue = calculatePercentile(metricValues, 5);
            break;
          case "25th":
            chosenPercentileValue = calculatePercentile(metricValues, 25);
            break;
          case "50th":
            chosenPercentileValue = calculatePercentile(metricValues, 50);
            break;
          case "75th":
            chosenPercentileValue = calculatePercentile(metricValues, 75);
            break;
          case "95th":
            chosenPercentileValue = calculatePercentile(metricValues, 95);
            break;
          default:
            console.error(`Unknown percentile choice: ${percentile}`);
            chosenPercentileValue = calculatePercentile(metricValues, 50);
        }

        result.push({
          age: age,
          metric: metric,
          percentile: percentile,
          value: chosenPercentileValue,
        });
      }
    }
  }

  //  const stringifiedData = JSON.stringify(result, null, 4);
  //  console.log("stringified percentileCalcs data", stringifiedData);

  return result;
};

export default calculatePercentiles;
