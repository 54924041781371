import styled from "styled-components";

export const BodyWrapper = styled.div`
  padding: 4rem;
  overflow: auto;

  @media (max-width: 768px) {
    padding: 1.2rem;
  }
`;

export const HeaderContainer = styled.div`
  height: 195px;
  display: flex;
  flex-direction: column; /* Stack children vertically */
  align-items: flex-start; /* Align items to the left */
  padding: 1.2rem 1.8rem;
  gap: 1rem;
  // box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.12);
  position: relative;
`;

export const LogoTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  img {
    height: 45px;
  }

  h1 {
    font-size: 1.7rem;
    margin: 0;
  }
`;

export const HeroContainer = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto auto auto auto auto auto auto;
  gap: 1rem;
  justify-content: center;

  @media (max-width: 1600px) {
    grid-template-columns: 1fr;
  }

  @media (max-width: 390px) {
    grid-template-columns: 1fr;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;
