import React from "react";
import {
  StyledLandingPage,
  CenteredText,
  Heading,
  ItalicsSpan,
  ImageContainer1,
  ImageContainer2,
  Image1,
  Image2,
  ImageContainerWrapper,
} from "../styles/LandingPageStyles";

// Import your images
import myImage from "../assets/mockmagic website trial6-min.png";
import myImage2 from "../assets/mockmagic website trial5-min.png";

function LandingPage() {
  return (
    <StyledLandingPage>
      <CenteredText>
        {/* {window.innerWidth > 390 && ( */}
        <Heading>
          Retirement Projections. <ItalicsSpan>Reimagined.</ItalicsSpan>
        </Heading>
        <ImageContainerWrapper>
          {/* Image 1 */}
          <ImageContainer1>
            <Image1
              src={myImage} // Make sure this path is correct
              alt="histogram" // Provide a concise description here
              loading="lazy" // Enable lazy loading
            />
          </ImageContainer1>
          {/* Add some space between the images */}
          <div style={{ width: "1%" }}></div>

          {/* {window.innerWidth > 390 && (
              <ProjectionDetails data={projectionState} data2={finalData3} />
            )} */}

          <ImageContainer2>
            <Image2
              src={myImage2} // Make sure this path is correct
              alt="percentiles" // Provide a concise description here
              loading="lazy" // Enable lazy loading
            />
          </ImageContainer2>
        </ImageContainerWrapper>

        {/* <Paragraph>TBD</Paragraph> */}
      </CenteredText>
    </StyledLandingPage>
  );
}

export default LandingPage;
