import styled from "styled-components";

export const StyledLandingPage = styled.div`
  display: flex;

  justify-content: flex-start; /* Align items to the top of the screen */
  align-items: center;

  border: 1px solid ${(props) => props.theme.colors[200]};
  // border-radius: 8px;

  @media (max-width: 390px) {
    height: 75vh;
  }

  @media (min-width: 391px) and (max-width: 1279px) {
    height: 78vh;
  }

  @media (min-width: 1280px) and (max-width: 1511px) {
    height: 86vh;
  }

  @media (min-width: 1512px) and (max-width: 1919px) {
    height: 75vh;
  }

  @media (min-width: 1920px) {
    height: 75vh;
  }
`;

export const CenteredText = styled.div`
  text-align: center;
  // margin-top: 10px; /* Add top margin to create space from the top */
`;

export const Heading = styled.h1`
  font-size: 2.5rem;
  margin-top: -80px;
  margin-bottom: 30px;
  margin-left: 20px;
  color: black;

  @media (max-width: 390px) {
    font-size: 1.15rem;
  }
`;
// ${(props) => props.theme.colors[500]} 15%
//color: ${(props) => props.theme.colors[500]};
export const Paragraph = styled.p`
  font-size: 6.5rem;
`;

export const ItalicsSpan = styled.span`
  font-style: italic;
  color: ${(props) => props.theme.colors[500]};
`;

export const ImageContainerWrapper = styled.div`
  display: flex;
  justifycontent: space-between;

  @media (max-width: 390px) {
    flex-direction: column; /* Stack items vertically on small screens */
  }
`;

export const ImageContainer1 = styled.div`
  aspect-ratio: 16/9;

  @media (max-width: 390px) {
    min-height: 9vh; /* Default min-height */
    margin-bottom: 40px; /* Add margin between stacked images */
  }

  @media (min-width: 391px) and (max-width: 1279px) {
    min-height: 25vh; /* Default min-height */
  }

  @media (min-width: 1280px) and (max-width: 1511px) {
    min-height: 25vh; /* Default min-height */
  }

  @media (min-width: 1512px) and (max-width: 1919px) {
    min-height: 25vh; /* Default min-height */
  }

  @media (min-width: 1920px) {
    min-height: 40vh; /* Default min-height */
  }
`;

export const ImageContainer2 = styled.div`
  aspect-ratio: 16/9;

  @media (max-width: 390px) {
    min-height: 15vh; /* Default min-height */
  }

  @media (min-width: 391px) and (max-width: 1279px) {
    min-height: 25vh; /* Default min-height */
  }

  @media (min-width: 1280px) and (max-width: 1511px) {
    min-height: 25vh; /* Default min-height */
  }

  @media (min-width: 1512px) and (max-width: 1919px) {
    min-height: 25vh; /* Default min-height */
  }

  @media (min-width: 1920px) {
    min-height: 40vh; /* Default min-height */
  }
`;

export const Image1 = styled.img`
  height: auto;
  object-fit: cover;

  @media (max-width: 390px) {
    width: 65%;
  }

  @media (min-width: 391px) and (max-width: 1279px) {
    width: 75%;
  }

  @media (min-width: 1280px) and (max-width: 1511px) {
    width: 75%;
  }

  @media (min-width: 1512px) and (max-width: 1919px) {
    width: 75%;
  }

  @media (min-width: 1920px) {
    width: 75%;
  }
`;

export const Image2 = styled.img`
  height: auto;
  object-fit: cover;

  @media (max-width: 390px) {
    width: 90%;
  }

  @media (min-width: 391px) and (max-width: 1279px) {
    width: 75%;
  }

  @media (min-width: 1280px) and (max-width: 1511px) {
    width: 90%;
  }

  @media (min-width: 1512px) and (max-width: 1919px) {
    width: 95%;
  }

  @media (min-width: 1920px) {
    width: 95%;
  }
`;
