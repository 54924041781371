const calculateFrequencyInBands = (data) => {
  if (!data || data.length === 0) {
    return null;
  }

  const assetBandSize = 100000; // In increments of $Xk
  const investmentReturnsBandSize = 2;
  const inflationRatesBandSize = 2;
  const ages = [...new Set(data.map((item) => item[1]))]; // Get unique ages from data
  const att_age = ages.filter(
    (age) => age >= 55 && age <= 90 && (age - 55) % 5 === 0
  );

  const dataMappings = {
    Assets: () => data.map((item) => item[20]),
    "Investment Returns": () => data.map((item) => 100 * item[8]),
    "Inflation Rates": () => data.map((item) => 100 * item[10]),
  };

  const bandMappings = {
    Assets: assetBandSize,
    "Investment Returns": investmentReturnsBandSize,
    "Inflation Rates": inflationRatesBandSize,
  };

  const result = [];

  for (let age of att_age) {
    for (let metric in dataMappings) {
      const selectedData = dataMappings[metric]().filter(
        (_, index) => data[index][1] === age
      );
      const bandSize = bandMappings[metric];
      const frequency = {};

      for (let value of selectedData) {
        // Calculate the band that the value falls into
        const band = Math.floor(value / bandSize) * bandSize;
        if (!frequency[band]) {
          frequency[band] = 0;
        }
        frequency[band]++;
      }

      // Convert the frequency object to a sorted array for better readability
      const sortedBands = Object.keys(frequency).sort(
        (a, b) => parseFloat(a) - parseFloat(b)
      );
      for (let band of sortedBands) {
        result.push({
          age: age,
          metric: metric,
          bandStart: parseFloat(band),
          bandEnd: parseFloat(band) + bandSize,
          count: frequency[band],
        });
      }
    }
  }

  // const stringifiedData = JSON.stringify(result, null, 4);
  // console.log("stringified Freq in Bands data", stringifiedData);

  return result;
};

export default calculateFrequencyInBands;
