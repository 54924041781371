const defaultData = {
  current_age: 55,
  retirement_age: 65,
  max_projection_age: 100,
  scenario_count: 10,
  pre_tax_contrib: 0,
  after_tax_contrib: 0,
  initial_assets_pre_tax: 3000000,
  initial_assets_after_tax: 0,
  ss_income: 50000,
  ss_start_age: 62,
  ss_cola_rate: 2,
  random_or_fixed_int_assump: "Random",
  fixed_int_assump: 5,
  random_int_min: -5,
  random_int_max: 10,
  random_or_fixed_infl_rate: "Random",
  fixed_infl_assump: 4,
  random_infl_min: 2,
  random_infl_max: 8,
  target_wd: 80000,
  tax_rate_wd_pre_tax_asset: 25,
  tax_rate_wd_after_tax_asset: 0,
  family1_inheritance: 0,
  family2_inheritance: 0,
  inheritance_age_family1: 0,
  inheritance_age_family2: 0,
};

export default defaultData;
